import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../Button";
import RothoNotes from "../RothoNotes";
import DeliveryAvailability from "./Delivery-Availability/DeliveryAvailability";
import { ShippingMethods } from "./Shipping-methods/ShippingMethods";
import { ExtraServices } from "./ExtraServices";
import { useAuthContext } from "../../../hooks/use-context/useAuthContext";
import { useShippingContext } from "../../../hooks/use-context/useShippingContext";
import { useCallback, useEffect, useState } from "react";
import client from "../../../api/client";
import { CartClientInfo } from "../CartClientInfo";
import useCart from "../../../api/carts/useCart";
import useFreightCost from "../../../api/freight-cost/useFreightCost";
import { DeliveryAddress } from "./Delivery-addresses/DeliveryAddress";
import { PaymentConditions } from "./PaymentConditions";
import { Slide, ToastContainer } from "react-toastify";
import AddAddressModal, { Address } from "../../templates/AddAddressModal";
import useExtraServices from "../../../api/extra-services/useExtraServices";
import { Loader } from "../../Loader";
import useCartAddressMutations from "../../../api/addresses/useCartAddressMutations";

export default function Shipping() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    // context
    const { user, roles } = useAuthContext();
    const { free_port, delivery_address, shipping_method, shipping_methods, additional_freight_cost, manual_freight_cost, manual_shipping_method, delivery_notice_info, rotho_notes, payment_terms, payment_method, delivery_restriction, setManualFreightCost, setManualShippingMethod, setAdditionalFreightCost } = useShippingContext();

    // state
    const [openNewAddres, setOpenNewAddress] = useState(false);
    const [expeditionDays, setExpeditionDays] = useState({});
    const [error, setError] = useState("");
    const [defaultValues, setDefaultValues] = useState<Address | null>(null);

    // react query
    const { data: cart, isLoading: isCartLoading, isFetching: isCartFetching } = useCart(id);
    const { updateCartAddress } = useCartAddressMutations();

    const { isLoading, isFetching } = useFreightCost({
        customer_code: cart?.data?.customer?.code,
        delivery_address: cart?.data?.address,
        cart_total_weight: cart?.data?.total_weight,
        cart_net_price: cart?.data?.total_net_price,
        cart_shipping_method: cart?.data?.shipping_info?.shipping_method
    });

    const { extraServices } = useExtraServices(id);

    const handlePreviousStepClick = (e: any) => {
        e.preventDefault();
        navigate(-1);
    };

    const addNewAddress = (formData: Address) => {
        updateCartAddress.mutateAsync({ cart_id: cart?.data?.id, delivery_address: { ...formData, new_address: true } });

        setManualFreightCost("");
        setManualShippingMethod("");
        setAdditionalFreightCost("");
    };

    const handleNextStepClick = async (e: React.SyntheticEvent) => {
        e.preventDefault();

        if (free_port && !manual_shipping_method) {
            setError("tipo di trasporto obbligatorio");
            window.scrollTo(0, 0);
            return;
        } else {
            setError("");
        }

        await updateShippingInfo();

        navigate(`/carts/${cart?.data?.id}/summary`);
    };

    // EXPEDITION DAYS
    const getExpeditionDays = useCallback(async () => {
        const params = {
            customer_code: cart?.data?.customer?.code,
            delivery_address: JSON.stringify(cart?.data?.shipping_info?.address),
        };

        const res = await client.get("shipping/delivery-times", { params: params });

        setExpeditionDays(res.data.data);
    }, [cart]);

    const updateShippingInfo = useCallback(async () => {
        const body = {
            shipping_method: shipping_method?.name || null,
            delivery_notice_name: delivery_notice_info?.name,
            delivery_notice_phone: delivery_notice_info?.phone,
            delivery_notice_note: delivery_notice_info?.note,
            delivery_restriction: delivery_restriction,
            note: rotho_notes,
            ...(payment_terms ? { payment_terms: payment_terms?.code } : {}),
            ...(payment_method ? { payment_method: payment_method?.code } : {}),
            ...(additional_freight_cost ? { additional_freight_cost: parseFloat(additional_freight_cost) } : {}),
            ...(manual_freight_cost ? { manual_freight_cost: manual_freight_cost } : {}),
            manual_shipping_method: manual_shipping_method
        };

        await client.put(`carts/${cart?.data?.id}/shipping-info`, body);

    }, [user?.auth_token, shipping_method?.name, delivery_address, delivery_notice_info?.name, delivery_notice_info?.phone, delivery_notice_info?.note, rotho_notes, cart?.data?.id, payment_terms, payment_method, additional_freight_cost, manual_freight_cost, manual_shipping_method, delivery_restriction]);

    useEffect(() => {
        if (cart) {
            getExpeditionDays();
        }
    }, [cart]);

    useEffect(() => {
        if (cart?.data?.shipping_info?.address?.new_address && openNewAddres) {
            setDefaultValues({
                name: cart?.data?.shipping_info?.address?.name,
                street: cart?.data?.shipping_info?.address?.street,
                country_id: cart?.data?.shipping_info?.address?.country_id,
                region_id: cart?.data?.shipping_info?.address?.region_id,
                city: cart?.data?.shipping_info?.address?.city,
                postalcode: cart?.data?.shipping_info?.address?.postalcode,
                phone: cart?.data?.shipping_info?.address?.phone
            });
        } else {
            setDefaultValues(null);
        }
    }, [openNewAddres, cart]);

    return (
        <div>
            {/* react toastfy */}
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />

            {
                cart &&
                <>
                    {(isCartLoading || isCartFetching) && <Loader />}

                    <CartClientInfo data={cart} />

                    {openNewAddres && <AddAddressModal open={openNewAddres} onClose={setOpenNewAddress} title={cart?.data?.shipping_info?.address?.new_address ? t("Modifica indirizzo") : t("Aggiungi nuovo indirizzo")} primaryActionLabel={cart?.data?.shipping_info?.address?.new_address ? t("Modifica") : t("Aggiungi")} callbackFn={addNewAddress} defaultValues={defaultValues} />}

                    <form onSubmit={handleNextStepClick}>
                        {
                            cart && !cart?.data?.heading?.shippingAddress && cart?.data?.customer?.type !== "DUMMY CUSTOMER" &&
                            <DeliveryAddress data={cart} setOpenNewAddress={setOpenNewAddress} />
                        }

                        <ShippingMethods shippingMethods={shipping_methods} cart={cart} isLoading={isLoading || isFetching} error={error} expeditionDays={expeditionDays} />

                        {
                            !roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) &&
                            <PaymentConditions data={cart} />
                        }

                        {
                            extraServices &&
                            <ExtraServices cart={cart} extraServices={extraServices} />
                        }

                        <DeliveryAvailability
                            cart={cart}
                            expeditionDays={expeditionDays}
                        />

                        <RothoNotes />

                        <div className="flex justify-between">
                            <Button variant={Button.variant.text} icon={Button.icon.letfArrow} onClick={e => handlePreviousStepClick(e)}>
                                {t("Indietro")}
                            </Button>
                            <Button isDisabled={isLoading || isFetching}>
                                {t("Procedi")}
                            </Button>
                        </div>
                    </form>
                </>
            }
        </div>
    );
}
