import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useSelectStyles } from "../../../hooks/useSelectStyles";
import { useAuthContext } from "../../../hooks/use-context/useAuthContext";
import { Section } from "../../Section";

type Props = {
    complaintTypes: any
    onChange: any
    error: any
    selectedCustomer: any
};

export const ComplaintsTypeList = ({ complaintTypes, onChange, error, selectedCustomer }: Props) => {
    const { t } = useTranslation();
    const { CustomStyles } = useSelectStyles();
    const { roles } = useAuthContext();

    return (
        <fieldset>
            <legend className="sr-only">{t("Problema riscontrato")}</legend>
            
            <Section title={"Problema riscontrato"}>
                <div className="w-full md:w-1/2">
                    <label htmlFor="country_id">{t("Seleziona il problema")}:</label>
                    <Select
                        options={complaintTypes}
                        styles={CustomStyles}
                        placeholder={t("Seleziona il problema riscontrato")}
                        loadingMessage={() => t("Caricamento in corso...")}
                        noOptionsMessage={() => t("Nessun risultato")}
                        onChange={onChange}
                        isDisabled={!selectedCustomer && !roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"])}
                    />
                    {error && <p className="text-sm text-rose-500 mt-1">{error.message}</p>}
                </div>
            </Section>
        </fieldset>
    );
};
